import React from "react";
import { Main } from "components/pages/LessonList";
import Layout from "layout";
import Seo from "seo";
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"

const Lessons = ({data}) => {

    
    return (
        <Layout>
            <Seo title={"授業検索"} />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item >
                    <Box maxWidth={1000} mx="auto" mt={1}>
                        <Main />
                    </Box>
                </Grid>
            </Grid>
        </Layout>
    )
}

export default Lessons
